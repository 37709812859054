(function ($) {
    "use strict";

       
    $(function ($) {

        // Activate validation on any and all forms
        $('form').each(function () {
            var $form = $(this);

            if ($form.attr('novalidate') !== undefined) {
                // no validation required so skip this form
                return;
            }

            // no special handling, just initialise validation
            $form.validate();
        });

    });


    // Turn checkboxes and radios into switches
    $('[data-toggle="switch"]').bootstrapSwitch();


}) (jQuery);